<template>
  <div>
    <BackCrumb />
    <h1 class="c-view-h1">{{$store.state.event.name}}</h1>
    <ui-cards>
      <ui-card>
        <template v-slot:head>Quicklinks</template>
        <ul>
          <li>
            <a class="cursor-pointer" @click="openSettings()">Eigenschaften</a>
          </li>
          <li>
            <a class="cursor-pointer" @click="openAttendees()">Teilnehmer</a>
          </li>
        </ul>
      </ui-card>
      <ui-card>
        <template v-slot:head>Statistiken</template>
      </ui-card>
    </ui-cards>
  </div>
</template>

<script>
import BackCrumb from "../../../components/Admin/Event/BackCrumb";

export default {
  name: "Dashboard",
  components: {
    BackCrumb
  },
  data(){
    return {

    }
  },
  methods: {
    openSettings(){
      this.$router.push({ name: 'AdminEventSettings', params: { id: this.$store.state.event.id } })
    },
    openAttendees(){
      this.$router.push({ name: 'AdminEventAttendees', params: { id: this.$store.state.event.id } })
    }
  }
}
</script>

<style scoped>

</style>
